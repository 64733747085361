import * as React from "react";
import Layout from "../components/Layout";
import { HtmlHead, ResponsiveImage, Link } from "@cdv/jazz-web/components";
import { Typography, Grid, Container, Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const Head = () => (
  <HtmlHead title="Pro řidiče | Metodické centrum pro dopravní psychologii">
    <meta name="foo" content="bar" />
  </HtmlHead>
);

export default function Page() {
  const aCss = {
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
  };
  return (
    <Layout isIndex={false}>
      <Container sx={aCss}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { lg: "40px", xs: "24px" },
            marginTop: "140px",
            textAlign: "left",
          }}
        >
          Jste řidič a chcete absolvovat terapeutický program?
        </Typography>
        <Typography variant="h3" sx={{ fontSize: { lg: "24px", xs: "18px" } }}>
          Do 30. 6. 2023 platí povinnost absolvovat terapeutický program na základě:
        </Typography>
        <Box>
          <ul>
            <li>rozhodnutí soudu nebo státního zástupce,</li>
            <li>doporučení Probační a mediační služby,</li>
            <li>rozhodnutí dopravního psychologa,</li>
            <li>dobrovolného rozhodnutí řidiče. </li>
          </ul>
        </Box>
        <Box>
          <strong>
            V rámci pilotních běhů terapeutických programů nabízíme účast na bezplatných skupinových
            programech realizovaných v Brně, Znojmě, Kroměříži, Prostějově a v Jihlavě.
          </strong>{" "}
          <br />
          <br />
          Chcete-li se přihlásit do pilotního programu, <Link to="">klikněte zde.</Link>
        </Box>
        <Typography variant="h3" sx={{ fontSize: "24px" }}>
          Od 1. 7. 2023 definuje povinnost absolvovat terapeutický program především zákon 361/2000
          Sb.
        </Typography>
        <Typography sx={{ marginTop: "10px", fontWeight: "700" }}>
          Na jeho základě bude program podmínkou vrácení řidičského oprávnění pro tyto řidiče:{" "}
        </Typography>
        <Box>
          <ul>
            <li>
              Řidiči, kterým byl uložen trest nebo správní trest zákazu činnosti spočívající v
              zákazu řízení motorových vozidel na dobu nejméně 18 měsíců
            </li>
            <li>
              Řidiči, kteří se v rámci podmíněného zastavení trestního stíhání nebo podmíněného
              odložení návrhu na potrestání zavázali, že po dobu nejméně 18 měsíců nebudou takovou
              činnost vykonávat
            </li>
            <li>
              Řidiči, kterým byl uložen správní trest zákazu činnosti za přestupek podle § 125c
              odst. 1 písm. d) – tedy řidiči, kteří odmítli test na alkohol nebo drogy.
            </li>
            <li>
              Řidiči, o jejichž účasti na programu rozhodl soud nebo státní zástupce na základě
              zvláštního právního předpisu.{" "}
            </li>
          </ul>
        </Box>
        <Typography>
          Chcete se přihlásit do terapeutického programu dle zákona č. 361/2000 Sb., přečtěte si{" "}
          <a href="">návod k přihlášení</a> a poté <a href="">klikněte zde</a>.
        </Typography>
        <Typography variant="h3" sx={{ fontSize: "24px" }}>
          Chcete vědět, ve kterých městech probíhají terapeutické programy?
        </Typography>
        <Typography sx={{ marginTop: "10px", fontWeight: "700" }}>
          Po najetí myši na zvolené místo se objeví adresa a kontaktní údaje.
        </Typography>
        <Typography>
          Interaktivní mapa ČR s označením měst (podobně jako na webu monitoringpau.cz), kde budou
          kurzy probíhat. Po najetí na konkrétní místo se objeví adresa a kontakt.
        </Typography>
      </Container>
    </Layout>
  );
}
